import React, { useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { getDeepLink } from "components/V2/Notifications/api";
import { RectangularQrCodeBorder } from "assets/svg/left_sidebar/CustomSvgIcons";
import classes from "./QrCode.module.css";
import { CustomTextSpan } from "../Components/Components";
export const QRCodeFloating = ({
  isBorderEnabled = true,
  size = 116,
  color = "var(--text-3)",
}) => {
  const [deeplinkUrl, setDeepLinkUrl] = useState(null);
  const [isQRblurred, setIsQRblurred] = useState(true);
  useEffect(() => {
    getQRCode().then((res) => {
      setDeepLinkUrl(res?.data?.data?.dynamic_url);
    });
  }, []);
  if (!deeplinkUrl) return null;
  if (!isBorderEnabled)
    return <QRCodeLogin deeplinkUrl={deeplinkUrl} size={size} color={color} />;
  return (
    <div className={classes.qrCodeFloating}>
      <div className={classes.parentImage}>
        <RectangularQrCodeBorder />
      </div>
      <div className={classes.childImage}>
        <QRCodeLogin deeplinkUrl={deeplinkUrl} size={size} color={color} />
      </div>
      {isQRblurred && (
        <div className={classes.blur}>
          <button onClick={() => setIsQRblurred(false)}>Show QR Code</button>
        </div>
      )}
      <div className={classes.appText}>
        <CustomTextSpan
          letterSpacing="1.04px"
          color={color}
          fontWeight="700"
          fontSize="13px">
          MOBILE APP
        </CustomTextSpan>
      </div>
    </div>
  );
};

export const QRCodeLogin = ({
  size,
  deeplinkUrl,
  color = "var(--text-1)",
  profileImage = null,
  isOnboarding = false,
}) => {
  return deeplinkUrl == null ? null : (
    <div
      className={classes.container}
      style={{
        backgroundColor: isOnboarding
          ? "var(--text-white) !important"
          : "transparent",
      }}>
      <QRCodeSVG
        value={deeplinkUrl}
        size={size}
        fgColor={color}
        bgColor={isOnboarding ? "var(--text-white) !important" : "var(--base)"}
      />
      {profileImage && (
        <img
          src={profileImage}
          alt="profile"
          className={classes.profileImage}
        />
      )}
    </div>
  );
};

export const getEncodedHeaders = () => {
  const sessionPrivateKey = localStorage.getItem("sessionPrivateKey");
  const tokenSerialized = JSON.parse(localStorage.getItem("tokenSerialized"));
  const tokenSignature = localStorage.getItem("tokenSignature");
  const userAddress = localStorage.getItem("userAddress");
  const sessionAddress = localStorage.getItem("sessionAddress");
  const walletType = localStorage.getItem("walletType");

  const loginData = {
    sessionPrivateKey,
    tokenSerialized,
    tokenSignature,
    userAddress,
    sessionAddress,
    walletType,
  };

  const loginDataStr = JSON.stringify(loginData);
  const encodedData = btoa(loginDataStr); // Base64-encoded string
  return encodedData;
};

export const getQRCode = async () => {
  const encodedData = getEncodedHeaders(); // Base64-encoded string

  const url = `https://0xppl.com/link?data=${encodedData}`;

  const deepLink = await getDeepLink({ url });

  return deepLink;
};

export default QRCodeLogin;
