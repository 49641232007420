import { useLayoutEffect } from "react";
// Define your colours here, and use them as your variables in css module.css files

export const dark = {
  "primary-color": "rgba(65, 121, 241, 1)",
  "primary-color20": "rgba(65, 121, 241, 0.2)",
  "primary-color40": "rgba(65, 121, 241, 0.4)",
  "primary-color12": "rgba(65, 121, 241, 0.12)",
  "primary-color60": "rgba(65, 121, 241, 0.60)",
  "primary-color10": "rgba(65, 121, 241, 0.1)",
  "light-primary-color": "rgba(65, 121, 241, 0.24)",
  "primary-color05": "rgba(65, 121, 241, 0.05)",
  "secondary-color": "rgba(238, 87, 172, 1)",
  "secondary-color20": "rgba(238, 87, 172, 0.2)",
  "gradient-secondary-fab": "linear-gradient(180deg, #EE57AC 0%, #EE577B 100%)",
  base: "rgba(18, 21, 23, 1)",
  "base-80": "rgba(18, 21, 23, 0.6)",
  "gradient-base-color-1": "#1D2022",
  "gradient-base-color-2": "#121517",
  "gradient-divider-base-color-1": "#6876841f",
  "gradient-divider-base-color-2": "#6876840a",
  background: "#121517",
  "background-2": "rgba(18, 21, 23, 1,0.5)",
  "background-gradient-1": "rgba(231, 233, 235, 1)",
  "background-gradient-2": "rgba(247, 247, 247, 1)",
  "base-light-only": "none",
  "border-dark": "rgba(255, 255, 255, 0.12)",
  "border-light": "rgba(255, 255, 255, 0.08)",
  "text-1": "rgba(255, 255, 255, 0.9)",
  "text-2": "rgba(255, 255, 255, 0.6)",
  "text-2-10": "rgba(104, 118, 132, 0.1)",
  "text-3": "rgba(255, 255, 255, 0.4)",
  "text-white": "rgba(255, 255, 255, 1)",
  success: "rgba(72, 157, 96, 1)",
  method: "rgb(45 134 144)",
  error: "rgba(233, 88, 68, 1)",
  warning: "rgba(255, 168, 0, 1)",
  "warning-light": "rgba(255, 168, 0, 0.1)",
  "backdrop-filter": "32px",
  "success-light": "rgba(72, 157, 96, 0.1)",
  "success-lighter": "rgba(72, 157, 96, 0.04)",
  "error-light": "rgba(233, 88, 68, 0.12)",
  "error-lighter": "rgba(233, 88, 68, 0.04)",
  "elevation-1": "rgba(255, 255, 255, 0.05)",
  "elevation-2": "rgba(255, 255, 255, 0.08)",
  "elevation-3": "rgba(255, 255, 255, 0.12)",
  "elevation-1f": "#1E2123",
  "explorer-edge": "rgba(255, 255, 255, 0.24)",
  navBg: "rgba(18, 21, 23, 1)",
  topNavBg: "rgba(255, 255, 255, 0.001)",
  shimmer1: "rgba(255, 255, 255, 0.12)",
  shimmer2: "rgba(255, 255, 255, 0.04)",
  "accent-1": "#2d8690",
  "card-background": "rgba(255, 255, 255, 0.05)",
  newsBg: "rgba(45, 134, 144, 0.06)",
  "card-hover": "rgba(104, 118, 132, 0.5)",
  "solid-fg": "#ffffff",
  "solid-bg": "#000000",
  "twitter-banner": "rgba(6, 72, 215, 0.2)",
  "recommended-tag": "#EE577B",
  "help-background": "rgba(7, 157, 57, 0.1)",
  "graphic-background": "#DED8F5",
  "info-background": "#261F05",
  "graphic-text": "#48406C",
  "gray-gradient-background":
    "linear-gradient(180deg, rgba(104, 118, 132, 0.02) 0%, rgba(24, 27, 30, 0.05) 100%)",
  "social-bg": "rgba(60,60,60,1)",
  "closed-bg": "rgba(181, 45, 244, 0.12)",
  "closed-text": "#B52DF4",
  purple: "#7930D7",
  "purple-light": "rgba(121, 48, 215, 0.12)",
  "warning-text": "#856C53",
};

export const light = {
  "primary-color10": "rgba(16, 90, 249, 0.1)",
  "primary-color20": "rgba(65, 121, 241, 0.2)",
  "primary-color40": "rgba(65, 121, 241, 0.4)",
  "primary-color12": "rgba(65, 121, 241, 0.12)",
  "light-primary-color": "rgba(16, 90, 249, 0.24)",
  "primary-color": "#0648D7",
  "primary-color60": "rgba(16, 90, 249, 0.60)",
  "primary-color05": "rgba(16, 90, 249, 0.05)",
  "secondary-color": "rgba(238, 87, 172, 1)",
  "secondary-color20": "rgba(238, 87, 172, 0.2)",
  "gradient-secondary-fab": "linear-gradient(180deg, #EE57AC 0%, #EE577B 100%)",
  base: "#ffffff",
  "base-80": "rgba(255,255,255,0.8)",
  "backdrop-filter": "16px",
  "gradient-base-color-1": "rgba(255, 255, 255, 1)",
  "gradient-base-color-2": "rgba(246, 246, 246, 1)",
  "gradient-divider-base-color-1": "#6876841f",
  "gradient-divider-base-color-2": "#6876840a",
  background: "#F8F8F9",
  "background-2": "rgba(248, 248, 249, 0.5)",
  "base-light-only": "#ffffff",
  "border-dark": "rgba(104, 118, 132, 0.1)",
  "border-light": "rgba(104, 118, 132, 0.05)",
  "text-1": "rgba(27, 31, 34, 1)",
  "text-2": "rgba(104, 118, 132, 1)",
  "text-2-10": "rgba(104, 118, 132, 0.1)",

  "text-3": "rgba(104, 118, 132, 0.6)",
  "text-white": "#ffffff",
  success: "rgba(7, 157, 57, 1)",
  method: "rgb(45 134 144)",
  "success-light": "rgba(7, 157, 57, 0.12)",
  "success-lighter": "rgba(7, 157, 57, 0.04)",
  error: "rgba(226, 76, 76, 1)",
  "error-light": "rgba(226, 76, 76, 0.12)",
  "error-lighter": "rgba(226, 76, 76, 0.04)",
  warning: "rgba(247, 142, 30, 1)",
  "warning-light": "rgba(247, 142, 30, 0.1)",
  "elevation-1": "rgba(104, 118, 132, 0.04)",
  "elevation-2": "rgba(104, 118, 132, 0.08)",
  "elevation-3": "rgba(104, 118, 132, 0.12)",
  "elevation-1f": "#F9FAFA",
  "explorer-edge": "rgba(104, 118, 132, 0.24)",
  navBg: "rgba(255, 255, 255, 1)",
  topNavBg: "rgba(255, 255, 255, 0.9)",
  shimmer1: "rgba(104, 118, 132, 0.16)",
  shimmer2: "rgba(104, 118, 132, 0.08)",
  "accent-1": "#2d8690",
  "bar-0": "#8ecae6",
  "bar-1": "#219ebc",
  "bar-2": "#023047",
  "bar-3": "#ffb703",
  "bar-4": "#fb8500",
  "card-background": "rgba(104, 118, 132, 0.03)",
  newsBg: "rgba(45, 134, 144, 0.06)",
  "card-hover": "rgba(6, 72, 215, 0.12)",
  "solid-fg": "#ffffff",
  "solid-bg": "#000000",
  "twitter-banner": "rgba(6, 72, 215, 0.2)",
  "recommended-tag": "#EE577B",
  "help-background": "rgba(7, 157, 57, 0.1)",
  "graphic-background": "#DED8F5",
  "info-background": "#FBECB5",
  "graphic-text": "#48406C",
  "gray-gradient-background":
    "linear-gradient(180deg, rgba(104, 118, 132, 0.02) 0%, rgba(24, 27, 30, 0.05) 100%)",
  "social-bg": "#EAEBEC",
  "closed-bg": "rgba(181, 45, 244, 0.12)",
  "closed-text": "#B52DF4",
  purple: "#7930D7",
  "purple-light": "rgba(121, 48, 215, 0.12)",
  "lens-connect": "#42673B",
  "farcaster-connect": "#855DCD",
  "warning-text": "#856C53",
};

// Hook
const useTheme = (theme) => {
  useLayoutEffect(
    () => {
      // Iterate through each value in theme object
      for (const key in theme) {
        // Update css variables in document's root element
        document.documentElement.style.setProperty(`--${key}`, theme[key]);
      }
    },
    [theme] // Only call again if theme object reference changes
  );
};
export default useTheme;
